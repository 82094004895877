<template>
  <v-dialog 
    v-model="createAlbumDialog"
    width="500"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <h3 style="margin-bottom:15px;">
          {{ formHeading }}
        </h3>
        <div class="form-card">
          <Form
            ref="form"
            :validation-schema="schema"
            @submit="onSubmit"
          >
            <div class="form-row">
              <div class="form-col-full">
                <label for="album_name">Album Name<span>*</span> </label>
                <Field
                  v-model="albumName"
                  name="album_name"
                  type="text"
                  placeholder="Album Name"
                />
                <ErrorMessage name="album_name" /> 
                <span />
              </div>
            </div>

             


            <div class="form-row">
              <div class="form-col-full">
                <label for="description">Cover Picture<span>*</span></label>
                <div style="position: relative;">
                  <file-pond
                    ref="pond"
                    name="file"
                    class-name="my-pond"
                    label-idle="<p class='upload-icon'>Drag or upload your files</p>"
                    allow-multiple="false"
                    accepted-file-types="image/jpeg, image/png"
                    max-file-size="5MB"
                    max-files="1"
                    :files="myFiles"
                    :server="serverOptions"
                    credits="false"
                    image-preview-min-height="44"
                    image-preview-max-height="100"
                    allow-file-size-validation="true"
                    label-max-file-size-exceeded="File is too large"
                    @onload="getResponse"
                    @addfile="onAddFile"
                    @init="handleFilePondInit"
                  />
				  <Field
                    v-model="imageUrl"
                    name="imageUrl"
                    type="hidden"
                  />
                  <ErrorMessage name="imageUrl" /> 
                  <div class="file-limitation">
                    Files must be under 5 MB and can be in image (JPG, PNG) format
                  </div>
                  
                </div>
              </div>
            </div>


            <div class="form-row">
              <div class="form-col-full">
                <div class="form-actions">
                  <button
                    v-show="!albumId"
                    type="button"
                    @click="resetForm"
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    :disabled="saveAlbum?true:false"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>

<script setup>

import { useStore} from 'vuex';
import {ref,inject,watch} from 'vue'
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { createAlbum, getImage, getAlbum ,getAlbumCount} from '@/service/helper-service.js';


const FilePond = vueFilePond(FilePondPluginFileValidateType,FilePondPluginFileValidateSize, FilePondPluginImagePreview);

const snackbar= ref(false);
const text= ref('');
const store = useStore(); 
const imageUrl = ref('');
const formHeading = ref('Create Album')



const timeout =ref(2000);
const createAlbumDialog =  ref(false);
const openAlbumDialog = inject('openAlbumDialog');
const albumId = inject('albumId');

const albumName = ref('');
const saveAlbum = ref(false);

watch(openAlbumDialog, (newValue) => {
	console.log("newValue",newValue.status);
	createAlbumDialog.value = newValue.status
});


watch(albumId, (newValue) => {
	console.log("GET albumId",newValue);
	if(newValue){
		formHeading.value = "Edit Album";
		editAlbum(newValue);
	}
});

const closeDialog = ()=>{
	openAlbumDialog.status =false;
	formHeading.value = "Create Album";
	albumName.value = "";
	albumId.value = "";
	imageUrl.value = "";
	myFiles.value =[];
	form.value.resetForm();
  
}

const form  = ref();

const schema = yup.object({
	album_name:yup.string().required('Please enter Album Name'),
	imageUrl:yup.string().required('Please upload Cover Picture')
	
});





const onSubmit = async(data) => {
	saveAlbum.value = true;
	const method = albumId.value ? 'put' : 'post';
	const url = albumId.value ? `/gallery/${albumId.value}` : `/gallery`;
	const payload = {
		"albumName": capitalizeString(data.album_name.trim()),
		"coverPicture": imageUrl.value
	}
	
	try {
		const response = await createAlbum(method,url,payload);
		if(response.status === 200){
			saveAlbum.value = false;
			
			store.state.gallery.tableRefresh = true;
			if(albumId.value !=''){
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Updated successfully',
					color:'success',
					timeout:2000});
			}else{
				updateAlbumCount()
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Added successfully',
					color:'success',
					timeout:2000});
			}
			
			closeDialog();
		}
	} catch (error) {
		saveAlbum.value = false;
		if (error.response) {
			if(error.response.data.message){
				console.log("error.response.data.message",error.response.data.message);
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
					
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			console.log("error.message",error.message)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}	

};

const updateAlbumCount = async() =>{
	try {
		const response = await getAlbumCount(`/count/gallery`);
		if(response.status === 200){
			let galleryCounts = response?.data?.data; 
			console.log("galleryCounts",galleryCounts);
        
			store.state.gallery.allGallery = galleryCounts?.allGallery;
			store.state.gallery.published = galleryCounts?.published;
			store.state.gallery.unPublished = galleryCounts?.unPublished;
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				console.log("error.response.data.message",error.response.data.message);
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
					
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			console.log("error.message",error.message)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
}

const resetForm = ()=>{
	form.value.resetForm()  
}


const editAlbum = async (id) => {
	try {
		const response = await getAlbum(`/gallery/${id}`);
		if(response.status === 200){
			console.log("edit Album",response)
			console.log(response?.data?.data[0].albumName);
			let albumDetail =  response?.data?.data[0];
			albumName.value =  albumDetail?.albumName;
        
			if(albumDetail?.coverPicture?.split('/profile/')[1]){
				imageUrl.value = albumDetail?.coverPicture;
				loadImage(albumDetail?.coverPicture?.split('/profile/')[1])
			}else{
				myFiles.value = [];
			}
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				console.log("error.response.data.message",error.response.data.message);
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			console.log("error.message",error.message)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
		
};





const pond = ref(null);
const  myFiles = ref([])
const handleFilePondInit = ()=> {
	console.log('FilePond has initialized');
	// example of instance method call on pond reference
	pond.value.getFiles();
}


const serverOptions = {
	// url: coverUploadUri.value,
	process: (fieldName, file, metadata, load, error, progress, abort) => {
		
		const formData = new FormData();
		formData.append(fieldName, file, file.name);
            
		const request = new XMLHttpRequest();
		request.open('POST', process.env.VUE_APP_SERVICE_URL+'/files');
	
		request.setRequestHeader('Authorization', 'Bearer '+store.state.authentication.token);
		request.upload.onprogress = (e) => {
			progress(e.lengthComputable, e.loaded, e.total);
		};

		request.onload = function () {
			
			if (request.status == 200) {
				
				// the load method accepts either a string (id) or an object
				
				const response = JSON.parse(request.responseText);
				imageUrl.value = response?.data;
				load();
			}  else {
				// Can call the error method if something is wrong, should exit after
				console.log('error',request.status);
				if(request.status == 404){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:request.statusText,
						color:'danger',
						timeout:2000});
				}else{
					console.log('request',JSON.parse(request.responseText))

					let jsonData = JSON.parse(request.responseText);
					if(jsonData?.message =='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}else{
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:jsonData?.message,
							color:'danger',
							timeout:2000});
					}
						
				}

			}
		};

		request.onerror = () => {
			// Check if the browser indicates a network error
			if (!navigator.onLine) {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Network error',
					color:'danger',
					timeout:2000});
			} 
		};
	

		request.send(formData);

		return {
			abort: () => {
				console.log("Cancel triggred")
				// This function is entered if the user has tapped the cancel button
				request.abort();

				// Let FilePond know the request has been cancelled
				abort();
			},
		};

	},
	remove: (source, load) => {
		imageUrl.value = '';
		load();
	},
	revert: (source, load) => {
		imageUrl.value = '';
		load();
	}
	
	
}

const getResponse = (response)=>{
	console.log("respponnse",response);
}

const onAddFile =(error, file)=>{
	console.log('file added', { error, file })
}


const loadImage = async(fileName)=>{
	try {
		const response = await getImage(`/files?name=${fileName}`);
		if(response.status === 200){
			myFiles.value = [{source:'data:image/jpeg;base64,'+response?.data?.data,options:{type:'local'}}];
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
  
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			console.log("error.message",error.message)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}

	}    
}

function capitalizeString(inputString) {

	return inputString.split(' ').map(word => {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	}).join(' ');

}

</script>

<style scoped>
/* add even dialog form */
.add-event-dialog {position: relative!important;border-radius: 8px!important;}
.add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}

 /* form design    */

.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input[type='text'] + span,input[type='hidden'] + span ,textarea + span, .input-group + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex: 1; flex-direction: column;margin-right: 15px;margin-bottom: 12px;}
.form-col-half:last-child,.form-col-full:last-child{margin-right:0px;}
.form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select{border:1px solid #B2B2B2;border-radius: 5px;
    height: 39px;padding: 4px 13px;width: 100%;text-transform: capitalize;}
.form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
 .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}
button:disabled {
	opacity: 0.5; 
	cursor: not-allowed; 
}	
:deep() .filepond--root{margin-bottom:unset!important;}
/* date picker */
.input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
:deep() .form-col-half  .date-form-control{
  width: 100%;height: 37px;padding: 12px;}

 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  
  .input-group-append {
  display: flex;
  align-items: center; /* Aligns buttons vertically */
  /* Add additional styles as needed */
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
.radio-group{display: flex;}
.radio-input-wrap{display: flex;margin-right: 12px;}
.radio-input-wrap input{margin-right: 12px;}
.arr-btns-group{display: flex;
    align-items: self-end;
    height: 100%;}
.arr-btns{background-color: #007CBA;
    color: white;
    width: 39px;
    height: 37px;
    border-radius: 5px;
    margin-bottom: 3px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;}

    :deep() .upload-icon{position:relative;margin-top: 42px;}
    :deep() .upload-icon::after{
     width: 97px;
    content: '';
    height: 97px;
    background-image: url(../../assets/images/file-upload.png);
    background-size: contain;
    display: flex;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -75%);}  
	
	:deep() .filepond--drop-label{min-height: 10.75em;border:2px dashed #C5C5C5;border-radius: 9px;}
	.file-limitation{color: gray;font-size: 14px;font-weight: 300;}
</style>
