import apiClient from '@/service/api-service.js';


// Member
export const getMemberCount = (path) => {
	return apiClient.get(path);
};

export const getMemberList = (path,params) => {
	return apiClient.get(path, {
		params:params
	});
};

export const getMember = (path) => {
	return apiClient.get(path);
};

export const activateUser = (path,body) => {
	return apiClient.put(path,body);
};

export const deactivateUser = (path,body) => {
	return apiClient.put(path,body);
};

export const generateCredentials = (path,body) => {
	return apiClient.post(path,body);
};



// Add Member
export const createMember = (method,path,body) => {
	return apiClient[method](path,body);
};

export const uploadImage = (path,body) => {
	return apiClient.post(path,body);
};

export const deleteProfile = (path) => {
	return apiClient.delete(path);
};

export const getImage = (path) => {
	return apiClient.get(path);
};

// Broadcast
export const createBroadcast = (method,path,body) => {
	return apiClient[method](path,body);
};

export const getAllAlbum = (path) => {
	return apiClient.get(path);
};

export const getAttachments = (path) => {
	return apiClient.get(path);
};

export const getBroadcastList = (path,params) => {
	return apiClient.get(path, {
		params:params
	});
};

// Album List
export const createAlbum = (method,path,body) => {
	return apiClient[method](path,body);
};


export const getAlbumList = (path) => {
	return apiClient.get(path);
};

export const deleteAlbumData = (path) => {
	return apiClient.delete(path);
};

export const getAlbumCount = (path) => {
	return apiClient.get(path);
};

// Gallery List
export const updateCaption = (path,body) => {
	return apiClient.put(path,body);
};

export const getAlbum = (path) => {
	return apiClient.get(path);
};

export const albumVisibility = (path,body) => {
	return apiClient.put(path,body);
};

export const uploadGalleryImage = (path,body) => {
	return apiClient.put(path,body);
};

export const deleteGalleryImage = (path) => {
	return apiClient.delete(path);
};

// Vakalat
export const createVakalat = (path,body) => {
	return apiClient.put(path,body);
};

export const getVakalatList = (path,params) => {
	return apiClient.get(path, {
		params:params
	});
};

export const getVakalatCount = (path) => {
	return apiClient.get(path);
};

// OfficeBearer
export const createOfficeBearer = (path,body) => {
	return apiClient.put(path,body);
};

export const getOfficeBearerList = (path,params) => {
	return apiClient.get(path, {
		params:params
	});
};

export const getMemberRole = (path) => {
	return apiClient.get(path);
};

export const getAllMemberList = (path) => {
	return apiClient.get(path);
};




// Add more API calls as needed
