import axios from 'axios';
// Create an axios instance
const apiClient = axios.create({
	baseURL: process.env.VUE_APP_SERVICE_URL, // Base URL of your API
	headers: {
		'Content-Type': 'application/json'
	},
	timeout: 10000, // Request timeout
});

// Add a request interceptor
apiClient.interceptors.request.use(
	config => {
		// Do something before request is sent
		const token = localStorage.getItem('token'); // or any other storage you're using

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	error => {
		// Do something with request error
		return Promise.reject(error);
	}
);

// Add a response interceptor
apiClient.interceptors.response.use(
	response => {
		// Do something with response data
		return response;
	},
	error => {
		// Do something with response error
		return Promise.reject(error);
	}
);

export default apiClient;
