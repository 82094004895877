<template>
  <div class="logo">
    <img
      src="../assets/images/tnbc-logo.png"
      alt="logo"
    >
  </div>
  <v-container>
    <h1>Privacy Policy</h1>
    <p>Thank you for using the Nagercoil Bar Directory app, provided by Comorin Consulting Services. This Privacy Policy outlines how we handle information collected from users of our app.</p>
    <h3>Information Collection</h3>
    <p>The Nagercoil Bar Directory app serves as a directory of lawyers who are members of the Nagercoil Bar Association. We do not collect any personal information from app users. We do not request or store any personally identifiable information such as names, email addresses, phone numbers, or locations.</p>
    <h3>Use of Information</h3>
    <p>Since we do not collect personal information, we do not use it for any purpose. The app is designed to provide a public directory of lawyers who are members of the Nagercoil Bar Association. Users can search and view lawyer profiles within the app.</p>
    <h3>Information Security</h3>
    <p>We take the security of your information seriously. While we do not collect personal data, we ensure that the app is hosted on secure servers. We employ appropriate measures to protect the integrity and confidentiality of the data within the app.</p>
    <h3>Third-Party Services</h3>
    <p>The Nagercoil Bar Directory app does not use any third-party services or integrations. All data within the app is sourced from publicly available information provided by the Nagercoil Bar Association.</p> 
    <h3>Changes to Privacy Policy</h3>
    <p>We reserve the right to make changes to this Privacy Policy as necessary. Any updates will be posted on this page. We recommend reviewing this Privacy Policy periodically for any revisions.</p>
    <h3>Contact Us</h3>
    <p>If you have any questions or concerns about our Privacy Policy or the use of the Nagercoil Bar Directory app, please feel free to contact us.</p>
    <a href="mailto:info@comorin.co">info@comorin.co</a>
    <p>By using the Nagercoil Bar Directory app, you agree to the terms outlined in this Privacy Policy. Thank you for your trust in Comorin Consulting Services.</p>
    <br>
  </v-container>
</template>




<style scoped>
 .logo img{width: 115px;
    margin: 21px auto 4px;
    display: block;}
h1{font-size: 28px;margin-bottom: 5px;}
 h3{margin-top: 20px;margin-bottom: 5px;font-size: 20px;}
 p{line-height: 25px;font-size: 15px;}
</style>


