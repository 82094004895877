import moment from 'moment';
export function capitalizeString(inputString) {

	return inputString.split(' ').map(word => {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	}).join(' ');
	
}

export function  truncateString(inputString, maxLength){
	if (inputString.length > maxLength) {
		return inputString.slice(0, maxLength) + '...';
	} else {
		return inputString;
	}
}

export function formattedDate(d){
	return moment(d).format('DD-MMM-YYYY');
}


export function memberActivity(login,activity){
	if(login){
		console.log("login,activity",login,activity);
		if (login > activity) {
			return getRelativeTime(activity) + ' (U)';
		} else if (login < activity) {
			return getRelativeTime(activity) + ' (L)';
		}
  
		return login;
	}else{
		return getRelativeTime(activity) + ' (U)';
	}
}



export function getRelativeTime (data){
	let localTime  = moment.utc(data).toDate();
	localTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
	let difference = moment(localTime).fromNow(); 
	return difference;
}