<template>
  <div class="tab-heading-and-action">
    <h3>{{ capitalizeString(type) }} Members </h3>
    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
          @input="searchMember"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
      
      <fa
        icon="filter"
        class="filter-btn"
        @click="openFilter"
      />
         
      <div
        v-if="showFilter === true"
        class="filtercard"
      >
        <fa
          icon="close"
          class="filter-close-btn"
          @click="closeFilter"
        />
        <div class="filter-action">
          <h3>Filter</h3>
          <div
            class="reset-btn pointer"
            @click="resetFilter"
          >
            RESET
          </div>
        </div>
         
        
        <!-- <select
          id="BusinessCategory"
          v-model="selectedAdvocate"
          name="BusinessCategory"
        >
          <option value="">
            Type of Advocate
          </option>
          <option value="Type 1">
            Type 1
          </option>
          <option value="Type 2">
            Type 2
          </option>
        </select>

        <select
          id="BusinessCategory"
          v-model="selectedFirm"
          name="BusinessCategory"
        >
          <option value="">
            Firm Registered
          </option>
          <option value="Type 1">
            Type 1
          </option>
          <option value="Type 2">
            Type 2
          </option>
        </select> -->
  
        <select
          id="gender"
          v-model="selectedGender"
          name="gender"
        >
          <option value="">
            Gender
          </option>
          <option value="Male">
            Male
          </option>
          <option value="Female">
            Female
          </option>
          <option value="Other">
            Other
          </option>
        </select>
      </div>
    </div>
  </div>
   
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    v-bind="refresh"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.first_name`]="{ item }">
      <v-tooltip
        v-if="item.first_name!='' && item.first_name.length > 8 "
        :text="item.first_name"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncatedString(item.first_name) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.first_name }}
      </div>
    </template> 

    <template #[`item.middle_name`]="{ item }">
      <v-tooltip
        v-if="item.middle_name!='' && item.middle_name.length > 8 "
        :text="item.middle_name"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncatedString(item.middle_name) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.middle_name }}
      </div>
    </template>  
	
    <template #[`item.last_name`]="{ item }">
      <v-tooltip
        v-if="item.last_name!='' && item.last_name.length > 8 "
        :text="item.last_name"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncatedString(item.last_name) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.last_name }}
      </div>
    </template>  


    <template #[`item.email`]="{ item }">
      <v-tooltip :text="item.email">
        <template #activator="{ props }">
          <div
            v-if="item.email!='-'"
            v-bind="props"
            class="pointer"
          >
            {{ truncatedString(item.email) }}
          </div>
        
          <div v-else>
            -
          </div>
        </template>
      </v-tooltip>
    </template>  

    <template #[`item.status`]="{ item }">
      <div :class="item.status">
        {{ item.status }}
      </div>
    </template>  
    <template #[`item.action`]="{ item }">
      <v-tooltip text="View">
        <template #activator="{ props }">
          <fa 
            v-bind="props"
            icon="eye"
            class="pointer"
            @click="viewMember(item.action)"
          />
        </template>
      </v-tooltip>

      <v-tooltip text="Edit">
        <template #activator="{ props }">
          <router-link :to="'/edit-members/'+item.action">
            <fa
              v-bind="props"
              icon="pen-to-square"
              class="pointer"
            />
          </router-link>
        </template>
      </v-tooltip>
    </template>
  </v-data-table-server>
  
  
   
  <div class="text-center">
    <!-- deactivate dialog -->
    <v-dialog 
      v-model="deactivateDialog"
      width="500"
    >
      <v-card class="delete-dialog">
        <div
          class="close-icon"
          @click="deactivateDialog = false"
        >
          <fa icon="close" />
        </div>
          
            
        <v-card-text>
          <h3> <fa icon="trash-can" /> Are you sure?</h3>
          Are you sure you want to deactivate this member? This action 
          cannot be undone.
        </v-card-text>
       
        <v-card-actions class="delete-action">
          <button @click="deactivateDialog = false">
            Cancel
          </button>
          <button @click="deactivateReasonConfirmation">
            Deactivate
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- deactivate dialog -->
  
  
    <!-- deactivate reason dialog -->
    <v-dialog 
      v-model="deactivateReasonDialog"
      width="500"
    >
      <v-card class="delete-dialog">
        <div
          class="close-icon"
          @click="deactivateReasonDialog = false;deactivateReason =''"
        >
          <fa icon="close" />
        </div>
          
            
        <v-card-text>
          <h3>Reason for Deactivate</h3>
        </v-card-text>
         
        <input
          v-model="deactivateReason"
          type="text"
        >
        <span class="err-msg">{{ errMessage }}</span> 
        <v-card-actions class="delete-action">
          <button @click="deactivateReasonDialog = false;deactivateReason =''">
            Cancel
          </button>
          <button 
            :disabled="deactivating?true:false"
            @click="deactivateMember"
          >
            Submit
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- deactivate reason dialog -->
  
    <!-- block dialog -->
    <v-dialog 
      v-model="blockDialog"
      width="500"
    >
      <v-card class="delete-dialog">
        <div
          class="close-icon"
          @click="blockDialog = false"
        >
          <fa icon="close" />
        </div>
          
            
        <v-card-text>
          <h3> <fa icon="trash-can" /> Are you sure?</h3>
          Are you sure you want to block this member? This action 
          cannot be undone.
        </v-card-text>
       
        <v-card-actions class="delete-action">
          <button @click="blockDialog = false">
            Cancel
          </button>
          <button @click="blockMember">
            Block
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- block dialog -->


    <!-- activate dialog -->
    <v-dialog 
      v-model="activateDialog"
      width="500"
    >
      <v-card class="delete-dialog activate-dialog">
        <div
          class="close-icon"
          @click="activateDialog = false"
        >
          <fa icon="close" />
        </div>
          
            
        <v-card-text>
          <h3> <fa icon="check" /> Are you sure?</h3>
          Are you sure you want to activate this member? This action cannot be undone. 
        </v-card-text>
       
        <v-card-actions class="delete-action ">
          <button @click="activateDialog = false">
            Cancel
          </button>
          <button
            class="activate-flat-button"
            :disabled="activating?true:false"
            @click="activateMember"
          >
            Activate
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- activate dialog -->
  
        
    <div
      v-if="viewDialog"
      class="view-dialog-overlay"
      @click.self="closeView"
    >
      <div class="view-member-modal drawer-active">
        <div class="view-modal-action">
          <fa
            icon="arrow-right-long"
            @click="closeView"
          />
       
          <h3>{{ truncatedString(memberView.fullName) }}</h3>
          <router-link :to="'/edit-members/'+memberId">
            <fa
              icon="edit"
            />
          </router-link>
          <img
            :src="memberView.imageUrl"
            alt="" 
          >
        </div>

        <div class="view-member-details">
          <div class="member-details-content">
            <h5>Full Name</h5>
            <p>{{ memberView.fullName }}</p>
            
            <h5>Date of Birth</h5>
            <p> {{ memberView.dob }}</p>

            <h5>Gender</h5>
            <p>{{ memberView.gender }}</p>

            <h5>Blood Group</h5>
            <p>{{ memberView.bloodGroup }}</p>
            
            <h5>Email</h5>
            <p>{{ memberView.email }}</p>

            <h5>Phone Number</h5>
            <p>{{ memberView.phone }}</p>

            <h5>Office Address Line 1</h5>
            <p>{{ memberView.addressLineOne }}</p>

            <h5>Office Address Line 2</h5>
            <p>{{ memberView.addressLineTwo }}</p>

            <h5>District</h5>
            <p>{{ memberView.district }}</p>

            <h5>Pincode</h5>
            <p>{{ memberView.pincode }}</p>

            <h5>Residental Address Line 1</h5>
            <p>{{ memberView.residentalAddressLineOne }}</p>

            <h5>Residental Address Line 2</h5>
            <p>{{ memberView.residentalAddressLineTwo }}</p>

            <h5>District</h5>
            <p>{{ memberView.residentalDistrict }}</p>

            <h5>Pincode</h5>
            <p>{{ memberView.residentalPincode }}</p>

            <h5>Date of Enrollment </h5>
            <p>{{ memberView.enrollmentDate }}</p>

            <h5>Enrollment No</h5>
            <p>{{ memberView.enrollmentNo }}</p>

        
            <h5>Status</h5>
            <p>{{ memberView.status }}</p>
            
            <button 
              v-show="memberView.status=='Active'" 
              @click="deactivateMemberConfirmation(memberId)"
            >
              Deactivate
            </button>

            <button
              v-show="memberView.status=='Inactive'"
              class="activate-button"
              @click="activateMemberConfirmation(memberId)"
            >
              Activate
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  
  
  
<script setup>
import axios from 'axios';
import moment from 'moment';
import {ref,reactive,watch,provide,inject,onBeforeMount,computed} from 'vue'
import { useStore,mapMutations } from 'vuex';
  
const store = useStore();  
const props = defineProps({
	type:{
		type:String,
		default:''
	}
}) 


    

const itemsPerPage = ref(5);
const offset = ref(0);
const headers =ref([
	{ title: 'First Name', value: 'first_name' },
	{ title: 'Middle Name', value: 'middle_name' },
	{ title: 'Last Name', value: 'last_name' },
	{ title: 'Email', value: 'email' },
	{ title: 'Phone Number', value: 'phone_number' },
	// { title: 'Gender', value: 'gender' },
	{ title: 'Enrollment No', value: 'enrollment_no' },
	{ title: 'Status', value: 'status' },
	{ title: 'Action', value: 'action' }
])
const serverItems =  ref([]);
const loading = ref(true);
const refresh =ref(false);
const totalItems = ref(0);
const memberId = ref('');
const memberView =reactive({
	imageUrl:require(`@/assets/images/avatar.svg`),
	imageName:'',
	fullName:'',
	gender:'',
	dob:'',
	bloodGroup:'',
	phone:'',
	email:'',
	addressLineOne:'',
	addressLineTwo:'',
	district:'',
	pincode:'',
	residentalAddressLineOne:'',
	residentalAddressLineTwo:'',
	residentalDistrict:'',
	residentalPincode:'',
	enrollmentDate:'',
	enrollmentNo:'',
	typeOfAdvocate:'',
	certificateNo:'',
	fax:'',
	vakalat:'',
	totalVakalat:'',
	isFirmRegistered:'',
	dateofJoining:'',
	status:'',
	companyName:'',
	designation:'',
	city:'',
	businessCategory:'',
	businessSubcategory:'',
	website:'',
	chapter:'',
	memberSince:'',
	lastActivity:''
})

const showFilter =ref(false);
const query = ref('');
const selectedAdvocate = ref('');
const selectedFirm = ref('');
const selectedGender =ref('');
const name =ref('');
const calories =ref('');
const search =ref('');
const page =ref('');
const deactivateDialog =  ref(false);
const deactivateReasonDialog = ref(false);
const deactivateReason =ref('');
const activateDialog = ref(false);
const errMessage =ref('');
const blockDialog = ref(false);
const viewDialog = ref(false);
const snackbar = ref(false);
const text = ref(''); 
const timeout =  ref(null);
const activating = ref(false);
const deactivating = ref(false);
const sendCredentialMail = ref(false);
const tableHeight = ref(0);

watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
});

watch(selectedGender, () => {
	search.value = String(Date.now())
});

	
onBeforeMount(() => {
	  if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
})



const	loadItems =  ({ page, itemsPerPage, sortBy })=>{
	offset.value = (page - 1) * itemsPerPage
	console.log("offset",offset.value);
	loading.value = true;
	console.log("process.env.VUE_APP_SERVICE_URL",process.env.VUE_APP_SERVICE_URL);
       
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/users?user=member${props.type=='all'?'':props.type=='active'?'&active=yes':'&active=no'}&limit=${itemsPerPage}&offset=${offset.value}`,{
			params:{
				value:name.value,
				gender: selectedGender.value},
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
					
			serverItems.value = []
			console.log('response.data.title',response.data);
			// serverItems = response.data.data;
			totalItems.value = response.data.totalCount;
			loading.value = false;
            
			let memberData = response.data.data;
			memberData.forEach((item,index) => {

				let memberList = {first_name: item?.firstName || '-',
					middle_name: item?.middleName || '-',
					last_name: item?.lastName || '-',
					email:item?.email || '-',
					phone_number:`${item.phone.countryCode?item.phone.countryCode:''} ${item.phone.number?item.phone.number:'N/A'}`,
					gender:item?.gender || '-',
					enrollment_no:item?.otherInformation?.enrollmentNo || '-',
					status:item.isActive?'Active':'Inactive',
					business_category: item?.industry || '-',
					chapter: item?.state || '-',
					member_since:formattedDate(item.createdAt),
					last_activity:memberActivity(item.loginDate?item.loginDate:'',item.updatedAt),
					action:item._id}
				serverItems.value.push(memberList)
						
			});
  
  
           
			// store.state.members.allMembers = response?.data?.totalCount;
                   
					
                    

		})
		.catch((error)=>{
			if (error.response) {
				if(error.response.data.message){

					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}
							
							

				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		});
  
          
}


const viewMember = (id) => {

		   
	memberId.value = id;
	memberView.imageUrl = require(`@/assets/images/loader.gif`);
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/users/${id}`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			     }})
		.then((response) => {
			viewDialog.value=true;
			console.log("response",response);
			let getMemberDetails = response.data.data[0];
			if(getMemberDetails?.profilePictureId && getMemberDetails?.profilePictureId!=''){
				loadImage(getMemberDetails?.profilePictureId)
			}else{
				memberView.imageUrl = require(`@/assets/images/avatar.svg`);
			}

          
			if(getMemberDetails?.title){
				memberView.fullName = getMemberDetails?.title +'. ';
			}

			if(getMemberDetails?.firstName){
				memberView.fullName+= getMemberDetails?.firstName;
			}

			if(getMemberDetails?.middleName){
				memberView.fullName+=' '+getMemberDetails?.middleName;
			}

			if(getMemberDetails?.lastName){
				memberView.fullName+=' '+getMemberDetails?.lastName;
			}
         
			// memberView.fullName = getMemberDetails?.title+ ". " +getMemberDetails?.firstName+ " " +getMemberDetails?.lastName;
			memberView.gender = getMemberDetails?.gender || 'N/A';
			memberView.dob = getMemberDetails?getMemberDetails.dateOfBirth?formattedDate(getMemberDetails.dateOfBirth):'N/A':'';
			memberView.bloodGroup = getMemberDetails?.bloodGroup || 'N/A';
			memberView.phone = `${getMemberDetails?.phone?.countryCode || ''} ${getMemberDetails?.phone?.number || 'N/A'}`;
			memberView.email = getMemberDetails?.email || 'N/A';

					
			memberView.addressLineOne = getMemberDetails?.businessDetails?.[0]?.address?.addressLine1 || 'N/A';
			memberView.addressLineTwo = getMemberDetails?.businessDetails?.[0]?.address?.addressLine2 || 'N/A';
			memberView.district = getMemberDetails?.businessDetails?.[0]?.address?.district || 'N/A';
			memberView.pincode = getMemberDetails?.businessDetails?.[0]?.address?.postalCode || 'N/A';
			memberView.residentalAddressLineOne = getMemberDetails?.residentialAddress?.addressLine1 || 'N/A';
			memberView.residentalAddressLineTwo = getMemberDetails?.residentialAddress?.addressLine2 || 'N/A';
			memberView.residentalDistrict = getMemberDetails?.residentialAddress?.district || 'N/A';
			memberView.residentalPincode = getMemberDetails?.residentialAddress?.postalCode || 'N/A';

			memberView.enrollmentDate = getMemberDetails?.dateOfEnrollment?formattedDate(getMemberDetails.dateOfEnrollment):"NA";
			// memberView.enrollmentDate = getMemberDetails?getMemberDetails.dateOfEnrollment?formattedDate(getMemberDetails.dateOfEnrollment):'N/A':'';
			memberView.enrollmentNo = getMemberDetails?.otherInformation?.enrollmentNo || 'N/A';
			memberView.typeOfAdvocate = getMemberDetails?.advocateType || 'N/A';
			memberView.certificateNo = getMemberDetails?.certificateNo || 'N/A';
			memberView.fax = getMemberDetails?.fax || 'N/A';
			memberView.vakalat = getMemberDetails?.vakalat || 'N/A';
			memberView.totalVakalat = getMemberDetails?.totalVakalat || 'N/A';
			memberView.isFirmRegistered = getMemberDetails?.isFirmRegistered || 'N/A';
			memberView.dateOfJoining = getMemberDetails?getMemberDetails.dateOfJoining?formattedDate(getMemberDetails.dateOfJoining):'N/A':'';
			memberView.status = getMemberDetails?.isActive?'Active':'Inactive' || 'N/A';
			memberView.companyName = getMemberDetails?.companyName || 'N/A';
			memberView.designation = getMemberDetails?.designation || 'N/A';
			memberView.city = getMemberDetails?.city || 'N/A';
			memberView.businessCategory = getMemberDetails?.industry || 'N/A';
			memberView.businessSubcategory = getMemberDetails?.industrySubCategory || 'N/A';
			memberView.website = getMemberDetails?.website || 'N/A';
			memberView.chapter = getMemberDetails?.state || 'N/A';
			memberView.memberSince = getMemberDetails?getMemberDetails.createdAt?formattedDate(getMemberDetails.createdAt):'N/A':'';
			// memberView.lastActivity =  getMemberDetails?getMemberDetails.updatedAt?relatedTime(getMemberDetails.updatedAt)+ ' (U)':'N/A':'';
			memberView.lastActivity = memberActivity(getMemberDetails.loginDate?getMemberDetails.loginDate:'',getMemberDetails.updatedAt);
		})
		.catch((error)=>{
			if (error.response) {
				if(error.response.data.message){

					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}

				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		});
}


const deactivateMemberConfirmation = (item) =>{
	memberId.value = item;
	deactivateDialog.value =true;
}

const deactivateReasonConfirmation = ()=>{
	deactivateDialog.value = false;
	deactivateReasonDialog.value = true;
}

const deactivateMember = ()=>{
	if(deactivateReason.value==''){
		errMessage.value = 'Please enter reason';
	}else{
		errMessage.value ='';
		deactivating.value = true;
		axios
			.put(`${process.env.VUE_APP_SERVICE_URL}/users/${memberId.value}`,
				{'isActive':false,
					'deleted':true,
					'reason':deactivateReason.value
				},
				{headers:{
					'Authorization': `Bearer ${store.state.authentication.token}`,
				}})
			.then((response) => {
				console.log(" deactivate response",response);
				deactivating.value = false;
				if(response.status === 200){
					deactivateDialog.value=false;
					viewDialog.value =false;
            
					// loading = true
					search.value = String(Date.now())
						
					deactivateReasonDialog.value = false;
					deactivateReason.value = '';
           
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Deactivated Successfully',
						color:'success',
						timeout:2000});
							 
					setTimeout(() => {
						loading.value = false
						store.state.members.activeMembers-=1;
						store.state.members.inactiveMembers+=1;
					}, 2000)
							
				}
			})
			.catch((error)=>{
				deactivating.value = false;
				if (error.response) {
					if(error.response.data.message){

						if(error.response.data.message ==='jwt expired'){
							store.commit('snackbar/showSnackbar',{
								show:true,
								message:'Token expired',
								color:'danger',
								timeout:2000});
							store.commit('authentication/logout',2000);
						}

					}else{
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:error.response.statusText,
							color:'danger',
							timeout:2000});
					}
				}else {
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.message,
						color:'danger',
						timeout:2000});
				}
			});
	}      
          
}

const blockMemberConfirmation = (item) =>{
	memberId.value = item;
	blockDialog.value =true;
}
	
const blockMember = ()=>{
	console.log(memberId.value);
	axios
		.put(`${process.env.VUE_APP_SERVICE_URL}/users/${memberId.value}`,
			{'memberStatus':'blocked'},
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log(" deactivate response",response);
			if(response.status === 200){
              
				blockDialog.value=false;
				viewDialog.value=false;
				search.value = String(Date.now())
				snackbar.value=true;
				text.value = "Blocked Successfully"; 
                          
				setTimeout(() => {
					loading.value = false
				}, 2000)
			}
		})
}

const activateMemberConfirmation = (item)=>{
	memberId.value = item;
	activateDialog.value=true;
}

const activateMember = ()=>{
	activating.value = true;
	axios
		.put(`${process.env.VUE_APP_SERVICE_URL}/users/${memberId.value}`,
			{'isActive':true,
				'deleted':false
			},
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
				activateDialog.value=false;
				viewDialog.value=false;
            
				// loading = true
				search.value = String(Date.now())
           
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Activated Successfully',
					color:'success',
					timeout:2000});
							 
				setTimeout(() => {
					loading.value = false
					store.state.members.activeMembers+=1;
					store.state.members.inactiveMembers-=1;
				}, 2000)

				activating.value = false;
							
			}
		})
		.catch((error)=>{
			activating.value = false;
			if (error.response) {
				if(error.response.data.message){

					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}

				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		});     
          
}



const openFilter = ()=>{
	showFilter.value = true;
}

const closeFilter = ()=>{
	showFilter.value = false;
}

const resetFilter = ()=>{
	selectedAdvocate.value ='';
	selectedFirm.value = '';
	selectedGender.value ='';
}

const memberActivity = (login,activity) =>{
	if(login){
		console.log("login,activity",login,activity);
		if (login > activity) {
			return relatedTime(activity) + ' (U)';
		} else if (login < activity) {
			return relatedTime(activity) + ' (L)';
		}
  
		return login;
	}else{
		return relatedTime(activity) + ' (U)';
	}
}

const formattedDate = (d) =>{
	return moment(d).format('DD-MMM-YYYY');
}

const relatedTime = (data)=>{
	let localTime  = moment.utc(data).toDate();
	localTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
	let difference = moment(localTime).fromNow(); 
	return difference;
}

const capitalizedStr = (str) =>{
	return str.charAt(0).toUpperCase() + str.slice(1);
}

const loadImage = (id)=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/profileUpload/${id}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
				// 'Content-Type': 'multipart/form-data'
			}})
		.then((response) => {
				
			if(response.status === 200){
				console.log(" Image decoded response",response.data.data[0].image);
				if(response.data.data[0].image){
					memberView.imageUrl = 'data:image/jpeg;base64,'+response.data.data[0].image;
				}else{
					memberView.imageUrl = require(`@/assets/images/avatar.svg`);
				}
				
			}
		})
		.catch((error)=>{
			memberView.imageUrl = require(`@/assets/images/avatar.svg`);
			if (error.response) {
				if(error.response.data.message){

					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
								 store.commit('authentication/logout',2000);
					}
							

				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		});
}

const truncatedString = (string)=>{
	let result = string.substring(0, 10);
	// Add dots if the original string length is longer than 8 characters
	if (string.length > 8) {
		result += "..";
	}
	return result;
}

const closeView = ()=>{
	memberView.fullName = "";
	viewDialog.value = false;
}

function capitalizeString(inputString) {

	return inputString.split(' ').map(word => {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	}).join(' ');

}
   
  
</script>
  
  <style scoped>
  .tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
  .tab-heading-and-action h3{font-size: 18px;}
  .tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
  
  
  .search-container{border-radius: 8px;
      border: 1px solid #E7E7E7;
      background: #F5F5F5;
      padding: 4px 5px;}
   .search-container .fa-magnifying-glass{color:#939393;margin-right: 8px;}  
   .search-container input{outline: unset;}
  
   .filter{position: relative;width: max-content;margin-left: auto;display: flex;align-items: center;}
  .filter-btn{position: relative;cursor: pointer;}
  .filtercard{position: absolute;top:30px;right:0px;width: 250px; background-color: #fff; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;padding: 12px;display: flex;flex-direction: column;z-index: 1020;}
  .filtercard select{border: 1px solid #B2B2B2;
      padding: 6px;
      margin-bottom: 12px;
      border-radius: 5px;
      cursor: pointer;
      outline: unset;
      color:#B2B2B2;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
      background-position: 100% 60%;
      background-repeat: no-repeat;
      background-size: auto 15%;
  }
  :deep() .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
      /* :deep() .filtercard > input{border: 1px solid gainsboro;
      border-radius: 5px;
      padding: 6px;
      margin-bottom: 12px;}      */
  .filter-action{display:flex;align-items:center;margin-bottom: 15px;}   
  .reset-btn{color:#007CBA;font-weight: 600;font-size: 14px;margin-left: 12px;}
  .filter-close-btn{width: max-content;
      margin-left: auto;
      display: block;
     cursor: pointer;}
  
  .v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
  .v-table :deep() thead tr > th{width: 120px;}
  .v-table :deep() tbody tr > td{text-align: left;}
  .v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
  .v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
  .v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
  .v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
  .v-table :deep() tbody tr > td .fa-pen-to-square{color: #353535;margin-right: 5px;}
  .v-table :deep() tbody tr > td .fa-circle-xmark{color:#EC1B1B;}
  .v-table :deep() tbody tr > td:nth-child(1),
  .v-table :deep() tbody tr > td:nth-child(2),
  .v-table :deep() tbody tr > td:nth-child(3),
  .v-table :deep() tbody tr > td:nth-child(5),
  .v-table :deep() tbody tr > td:nth-child(6){min-width: 150px;}
  .v-table :deep() tbody tr > td:nth-child(7){
	min-width: 100px;
  }
  .v-data-table-footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 4px;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
}
  .pointer{cursor: pointer;}
  
  .delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
  .delete-dialog input{ 
	border: 1px solid gray;
      border-radius: 4px;
      height: 38px;
      margin: 12px 24px 0;
	  padding:12px;
	  outline: unset;
      background: white;
  }
   .delete-dialog .err-msg{margin-left: 24px;
      color: red;
      font-size: 14px;}   
  .delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
  .v-card-text h3{display: flex;align-items: center;}
  
	button:disabled {
	opacity: 0.5; 
	cursor: not-allowed; 
	}
  .activate-button{border: 1px solid #8BC34A!important;
    color: #8BC34A!important;
	}

	.activate-flat-button{
		background-color: #8BC34A!important;color: white;border-color: #8BC34A!important;
	}
  .v-card-text .fa-check{
    color: #8BC34A;
    background-color: #CFFFD7;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;
    }
  .drawer-active{
      animation: fade-in 500ms forwards;
	}
  
	@keyframes fade-in{
	0%{
	opacity: 0;
	right: -320px;
	}
	100%{
	opacity: 1;
	right: 0px;
	}
	}
  .v-card-text .fa-trash-can{color: #EC1B1B;
      background-color: #FEE4E2;
      padding: 9px;
      border-radius: 50%;
      font-size: 15px;
      margin-right: 12px;}
  .delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
  .delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
  .delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
  .delete-action button{padding: 6px 13px;
      border-radius: 9px;
      border: 1px solid;}
  
   .view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;z-index:1040;width:100%;height: 100vh;}
   .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
   .view-modal-action{display: flex;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 12px;}
.view-modal-action .fa-pen-to-square{cursor: pointer;margin-left: 12px;}
.view-modal-action img{width: 60px;height:60px;border-radius:50%; margin-left: 12px;object-fit: cover;}
  .view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
  .view-member-details::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
  .member-details-content{position:sticky;text-align: left;}
  .member-details-content h5{color: #858585;font-weight: 500;
      font-size: 16px;}
  .member-details-content p{color: #242426;font-weight: 600;
      margin-bottom: 23px;}
   .member-details-content button{
      border: 1px solid #EC1B1B;
      color: #EC1B1B;
      padding: 8px 10px;
      display: block;
      margin: 0 auto 12px;
      border-radius: 5px;
      font-weight: 600;
      min-width: 200px;}  
    .Active,.Inactive{padding: 5px 6px;
      text-align: center;
      font-weight: 800;
      border-radius: 5px;
      border:1px solid #C2C2C2;}   
    .Active{background-color: #CFFFD7;} 
    .Inactive{background-color: #FFCFCF;}  

	@media only screen and (max-width: 600px){
		.tab-heading-and-action{flex-direction: column;}
		.filter{margin-left: unset;}
	}
  </style>
  
  