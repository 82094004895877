<template>
  <!-- Image preview -->
  <ImagePreview />
   
  <div class="v-container">
    <v-tooltip
      v-if="galleryList.albumName.length > 40"
      :text="galleryList.albumName"
    >
      <template #activator="{ props }">
        <h3
          v-bind="props"
          class="pointer"
        >
          {{ truncateString(galleryList.albumName,40) }}
        </h3>
      </template> 
    </v-tooltip>
    <h3 v-else>
      {{ truncateString(galleryList.albumName,40) }}
    </h3>
  </div>
  
 
  <div class="form-container">
    <div class="form-card">
      <div class="form-sets">
        <Form
          ref="form"
          :validation-schema="schema"
          @submit="onSubmit"
        >
          <label>Upload Picture<span>*</span></label>
          <div style="position: relative;">
            <file-pond
              ref="pond"
              name="file"
              class-name="my-pond"
              label-idle="<p class='upload-icon'>Drag or upload your files</p>"
              :allow-multiple="mode=='add'?'true':'false'"
              :allow-remove="mode=='add'?'true':'false'"
              accepted-file-types="image/jpeg, image/png"
              max-file-size="5MB"
              :files="myFiles"
              :server="serverOptions"
              image-preview-min-height="44"
              image-preview-max-height="100"
              allow-file-size-validation="true"
              abel-max-file-size-exceeded="File is too large"
              credits="false"
              @init="handleFilePondInit"
            />
            <div class="file-limitation">
              Files must be under 5 MB and can be in image (JPG, PNG) format
            </div>
           
            <Field
              v-model="imageUrl"
              name="imageUrl"
              type="hidden"
              placeholder="imageUrl"
            />
            <ErrorMessage name="imageUrl" />
          </div>

          <div
            v-if="mode!='add'"
            class="form-row"
          >
            <div class="form-col-full caption-field">
              <label for="caption">Caption</label>
              <Field
                v-model="caption"
                name="caption"
                type="text"
                placeholder="caption"
              />
              <ErrorMessage name="caption" />
            </div>
            <div class="add-caption">
              <button type="submit">
                <fa :icon="mode=='add'?'add':'arrows-rotate'" />
              </button>
              <button
                style="width:39px;"
                type="button"
                class="bg-danger"
                @click="cancelEdit"
              >
                <fa icon="xmark" />
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>

    <div class="form-card">
      <div v-if="galleryList.pictures.length > 0">
        <GalleryListCard
          v-for="(item, index) in galleryList.pictures"
          :id="item?._id || ''"
          :key="index"
          :index="index"
          :caption-name="item.caption"
          :file="item?.file || {}"
          :picture="item.picture?item.picture.split('/profile/')[1]:''"
          :data-url="item?.image"
          @delete-list="deleteListConfirmation"
          @edit-list="editList"
          @preview-image="previewImage"
        />
      </div>
      <div v-else>
        <h3 class="text-center mt-5">
          Gallery is empty
        </h3>
      </div>
    </div>
  </div>

  
  <br>  
    
  <div class="form-row">
    <div class="form-col-full">
      <div
        v-if="galleryList.pictures.length > 0"
        class="form-actions"
      >
        <button
          v-if="galleryQueueLength() > 0"
          type="button"
          @click="uploadList()"
        >
          <fa
            v-show="spinner===true"
            icon="spinner"
            spin
          /> 
          Upload 
        </button>

        <button
          v-else
          type="button"
          @click="publishAlbum(galleryList.status?false:true)"
        >
          <fa
            v-show="publishSpinner===true"
            icon="spinner"
            spin
          /> 
          {{ galleryList.status==false?'Unpublish':'Publish' }}
        </button>
      </div>
    </div>
  </div>
  

     
  <!-- delete list dialog -->
  <v-dialog 
    v-model="deleteListDialog"
    width="500"
  >
    <v-card class="delete-dialog">
      <div
        class="close-icon"
        @click="deleteListDialog = false"
      >
        <fa icon="close" />
      </div>
          
            
      <v-card-text>
        <h3> <fa icon="trash" /> Are you sure?</h3>
        Are you sure you want to delete this image? This action cannot be undone. 
      </v-card-text>
       
      <v-card-actions class="delete-action ">
        <button @click="deleteListDialog = false">
          Cancel
        </button>
        <button
          @click="deleteList(listIndex)"
        >
          Delete
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- delete list dialog -->
</template>
  
  
  
  
  
<script setup>


import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {ref,reactive, onMounted,onUpdated,provide} from 'vue'
import GalleryListCard from '@/components/gallery/GalleryListCard.vue'
import ImagePreview from '@/components/gallery/ImagePreview.vue'

  
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';


import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import {truncateString, capitalizeString } from '@/helper/index.js';
import {getAlbum, updateCaption, albumVisibility, uploadGalleryImage, deleteGalleryImage, getImage} from '@/service/helper-service.js';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImagePreview);



const route = useRoute();
const store = useStore(); 
  
const form  = ref();
  
const imageUrl = ref('');
const publishSpinner = ref(false);
const spinner = ref(false);

const albumId = ref(route.params.id);
const galleryId = ref('');

const caption = ref('');

const mode = ref('add');
const listIndex = ref(0);
const deleteListDialog = ref(false);

let openPreviewDialog = reactive({'status':false});
let previewImageData = reactive({'url':'','caption':''}); 
provide('openPreviewDialog',openPreviewDialog);
provide('previewImageData',previewImageData);
  


const galleryList = reactive({
	"albumName": '',
	"coverPicture": '',
	"pictures": []
})

  
const schema = yup.object({
	imageUrl:yup.string().required('Please upload Image'),
	caption:yup.string()
  
});
  



const editList = (index)=>{
	console.log(index);
	mode.value = 'edit';
	listIndex.value = index;
	if(galleryList.pictures[index].picture){
		caption.value = galleryList.pictures[index].caption;
		galleryId.value = galleryList.pictures[index]._id;
		// console.log("galleryList.pictures[index]._id",galleryList.pictures[index]._id)
		
		editImage(galleryList.pictures[index].picture.split('/profile/')[1])
		imageUrl.value = galleryList.pictures[index].picture;
	}
	
}


const previewImage = (image,caption)=>{
	openPreviewDialog.status = true; 
	previewImageData.url = image;
	previewImageData.caption = caption;
}





const onSubmit = async(data) => {
	console.log('data',data);
	mode.value = 'add';

	try{
		const response = await updateCaption(`/gallery/${albumId.value}?pictureId=${galleryId.value}`,
			{"caption":caption.value})
		if(response.status === 200){
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Caption Updated Successfully',
				color:'success',
				timeout:2000});
						
			console.log("update response",response);
			galleryList.pictures[listIndex.value].caption = capitalizeString(caption.value.trim());
			console.log("galleryList.picture",galleryList.pictures)
			form.value.resetForm();
	        myFiles.value = '';
				
		}
		
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});	

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}

}



  
const getAlbumDetails = async(id)=>{

	try{
		const response = await getAlbum(`/gallery/${id}`);
		if(response.status === 200){
			let albumData = response.data.data[0];
			galleryList.albumName = albumData?.albumName;
			galleryList.coverPicture = albumData?.coverPicture;
			galleryList.pictures = albumData?.pictures;
			galleryList.status = albumData?.deleted;
			console.log("galleryList.pictures",galleryList.pictures);
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
  
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			console.log("error.message",error.message)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}

	}

	
}

const editImage = async(fileName) => {

	try{
		const response = await getImage(`/files?name=${fileName}`)
		if(response.status === 200){
				
			myFiles.value = [{source:'data:image/jpeg;base64,'+response?.data?.data,options:{type:'local'}}];
        
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
  
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			console.log("error.message",error.message)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
	
	
    
}



onMounted(()=>{
    
	if(albumId.value){
		getAlbumDetails(albumId.value);

	}
  
      

 
})

onUpdated(()=>{
	// console.log("updated");
})




const pond = ref(null);
const  myFiles = ref([])
const handleFilePondInit = ()=> {
// example of instance method call on pond reference
	pond.value.getFiles();
}




const publishAlbum = async(status) =>{
     
	let setStatus = {}; 
	setStatus.albumName = galleryList.albumName;
	setStatus.coverPicture = galleryList.coverPicture;
	setStatus.deleted = status;
    
	try{
		const response = await albumVisibility(`/gallery/${albumId.value}`,
			setStatus)
		if(response.status === 200){
					
			let uploadedData = response.data.data;
			galleryList.status = uploadedData?.deleted;
						
			if(status == true){
				publishSpinner.value =false;
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Unpublished Successfully',
					color:'success',
					timeout:2000});
			}
			if(status == false){
				publishSpinner.value =false;
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Published Successfully',
					color:'success',
					timeout:2000});
			}
						
		}
		
	} catch (error) {
		spinner.value = false;
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}

	}
	
}



const serverOptions = {
	process: (fieldName, file, metadata, load, error, progress, abort) => {
		console.log('file',file);
		
		convertToFile(file)
		load();
		
	},
	
	processfile: (error, file) => {
		console.log("processfiles",file);
		if (!error) {
			// Remove the image preview after successful upload
		
			// pond.value.removeFile(file);
		}
	},
	revert: (source, load) => {
		load();
	}
	
	
}


const convertToFile = (file)=>{
	if (!file) {
		console.error("No file selected");
		return;
	}
  
	const reader = new FileReader();
  
	reader.onload = () => {
		console.log("reader.result",reader.result);
		galleryList.pictures.push({'image':reader.result,'file':file,'_id':'','picture':'','caption':''})
		console.log('galleryList.pictures',galleryList.pictures);
		console.log('file.id',pond.value.getFiles())
		
		
	};
  
	reader.onerror = error => {
		console.error("Error reading file:", error);
	};
  
	reader.readAsDataURL(file);
	
}
  

const uploadList = () =>{
	if(galleryList.pictures.length > 0){
		for(let i=0;i < galleryList.pictures.length;i++){
			if(galleryList.pictures[i]._id == ''){
				uploadImage(galleryList.pictures[i].file,i)
			}
		}
	}
}

const galleryQueueLength = ()=>{
	return galleryList.pictures.filter(item =>item._id =='').length;
}

const uploadImage = (file,index)=>{
	
	spinner.value = true;	
	const formData = new FormData();
	formData.append('file', file);
	axios
		.put(`${process.env.VUE_APP_SERVICE_URL}/gallery/${albumId.value}`,
			formData,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`
			}})
		.then((response) => {
					
			if(response.status === 200){
				spinner.value = false;
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Uploaded Successfully',
					color:'success',
					timeout:2000});
				galleryList.pictures[index]._id = response.data.data.pictures[response.data.data.pictures.length-1]._id;
				galleryList.pictures[index].picture = response.data.data.pictures[response.data.data.pictures.length-1].picture;
				galleryList.pictures[index].caption = '';
				// console.log("testing",galleryList.pictures);
			}
		})
		.catch((error)=>{
			spinner.value = false;
			if (error.response) {
				if(error.response.data.message){

					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.data.message,
						color:'danger',
						timeout:2000});	

				}else{
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		});
}

const deleteListConfirmation = (index) =>{
	listIndex.value = index;
	if(galleryList.pictures[index]._id==''){
		galleryList.pictures.splice(index,1)
	}else{
		deleteListDialog.value = true;
	}
}

const deleteList = (index)=>{
	console.log(index);
	if(galleryList.pictures[index]._id==''){
		galleryList.pictures.splice(index,1)
	}else{
		console.log("galleryList.pictures[index]._id",galleryList.pictures[index]._id);
		deleteImage(galleryList.pictures[index]._id,index)
	}
	
}

const deleteImage = async (id,index)=>{
   
	try{
		const response = await deleteGalleryImage(`/gallery/${albumId.value}?pictureId=${id}`)
		if(response.status === 200){
			console.log("Deleted",response.data.data);
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Deleted Successfully',
				color:'success',
				timeout:2000});
		        deleteListDialog.value = false;	
		        galleryList.pictures.splice(index,1)
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});	

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}

	}
}

const cancelEdit = () =>{
	mode.value = 'add';
	myFiles.value = [];
}
</script>
  
  
  
  
<style scoped>
.album-title{font-size:22px;}
.form-container{display:flex;}
.form-card{box-shadow: 0px 2px 12px 0px #80808040;
   border-radius: 12px;width: 97%;margin: 12px;
  background-color:white;padding: 17px;
 
 }
 .form-card:nth-child(1){
  flex-basis:55%;
 }
 .form-card:nth-child(2){
  flex-basis:45%
 }
 .form-sets{min-height:100%;}
 .form-sets label{margin:10px 0 12px;display: block; }
  .form-card h3{margin-bottom: 15px;}
  .form-row{display: flex;align-items: center;}
  label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
  label span{color:red;}
  input + span ,select + span ,.input-group + span,.vue-tel-input + span{color:red;text-align: left;font-size: 12px;}
  .form-col-half,.form-col-full{display: flex;flex-direction: column;flex:1;margin-bottom: 14px;}
  .form-col-half{margin-right:30px;}
  .form-col-half:last-child{margin-right:0px;}
  .form-profile{flex-basis: 20%;margin-right: 15px;}
  .profile-container{position: relative; width:170px;height: 170px;border-radius: 50%;margin: auto;
      display: block;}
  
      
      
  .title{flex-basis: 14%!important;flex:unset;}
  .form-col-half input,.form-col-full input,.form-col-half select,.form-col-full select{border:1px solid #B2B2B2;border-radius: 5px;
      height: 39px;padding: 4px 13px;width:100%;text-transform: capitalize;}
   .form-col-half input:focus,.form-col-full input:focus,.form-col-half select:focus{outline: unset;}   
   select{border: 1px solid #B2B2B2;
      padding: 6px;
      border-radius: 5px;
      cursor: pointer;
      outline: unset;
      color:black;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
      background-position: 100% 60%;
      background-repeat: no-repeat;
      background-size: auto 15%;
  }
  .form-actions{display: flex;justify-content: flex-end;}
  .form-actions button{background-color: #007CBA;
      color: white;
      border-radius: 7px;
      padding: 6px 28px;
      margin-left: 18px;}
  .error-msg{text-align: left;
      color: red;
      font-size: 12px;}
  :deep() .iti__selected-flag{background-color:unset;}
  
  /* date picker */
  .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
  :deep() .form-col-half  .date-form-control{
    width: 100%;height: 37px;padding: 12px;}
  
   :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
    
    .input-group-append {
    display: flex;
    align-items: center; /* Aligns buttons vertically */
    /* Add additional styles as needed */
  }
  .input-group-append button{margin-right: 7px;}
  .input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
  .radio-group{display: flex;}

  .dropzone__box {
    
    align-items: center;
    border: 2px dashed #C5C5C5;
    border-radius: 8px;
    padding: 17px;
    min-height: 350px;
    background-color: #F6F6F6;
}
:deep() .dropzone__message--style{position:relative;margin-top: 32px;}
:deep() .dropzone__message--style::after{
     width: 97px;
    content: '';
    height: 97px;
    background-image: url(../../assets/images/file-upload.png);
    background-size: contain;
    display: flex;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -75%);}
    .caption-field{flex-basis: 80%;}
    .add-caption{flex-basis: 20%;display: flex;
    align-items: center;}
    .add-caption button{background-color: #007CBA;color:white;padding: 8px 12px;
    border-radius: 7px;margin-top: 26px;
    margin-left:auto;
    display: block;}
    
    .pointer{cursor:pointer;}
    :deep() .upload-icon{position:relative;margin-top: 42px;}
    :deep() .upload-icon::after{
     width: 97px;
    content: '';
    height: 97px;
    background-image: url(../../assets/images/file-upload.png);
    background-size: contain;
    display: flex;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -75%);}  
    :deep() .filepond--drop-label{min-height: 10.75em;border:2px dashed #C5C5C5;border-radius: 9px;}

    button:disabled {
  opacity: 0.5; 
  cursor: not-allowed; 
  }
  .file-limitation{color: gray;font-size: 14px;font-weight: 300;}
  .delete-dialog{
    position: relative !important;
    background-color: #FFF9F9;
    border-radius: 12px !important;
   }
  .delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
  .v-card-text h3{display: flex;align-items: center;}
  
  .v-card-text .fa-trash{color: #EC1B1B;
      background-color: #FEE4E2;
      padding: 9px;
      border-radius: 50%;
      font-size: 15px;
      margin-right: 12px;}
  .delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
  .delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
  .delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
  .delete-action button{padding: 6px 13px;
      border-radius: 9px;
      border: 1px solid;}

  @media only screen and (max-width: 768px) {
    .form-container{flex-direction: column;}
    .form-card:nth-child(1),.form-card:nth-child(2){flex-basis: 100%;width:unset;}
    .form-col-half, .form-col-full{margin-right: unset;}
  }
  </style>
  
  