import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import LoginView from '../views/LoginView.vue'
import MembersView from '../views/MembersView.vue'
import AddMembersView from '../views/AddMembersView.vue'
import EditMembersView from '../views/EditMembersView.vue'
import GalleryView from '../views/GalleryView.vue'
import CreateGalleryView from '../views/CreateGalleryView.vue'
import AddGalleryView from '../views/AddGalleryView.vue'
import ManageOfficeBearersView from '../views/ManageOfficeBearersView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'


const routes = [
	{
		path: '/',
		name: 'login',
		component: LoginView,
		meta:{
			title:'The Nagercoil Bar -  Login',
			auth:false
		}
	},
	{
		path: '/members',
		name: 'members',
		component: MembersView,
		meta:{
			title:'The Nagercoil Bar -  Members',
			auth:true
		}
	},
	{
		path: '/add-members',
		name: 'add-members',
		component:AddMembersView,
		meta:{
			title:'The Nagercoil Bar - Add Members',
			auth:true
		}
	},
	{
		path: '/edit-members/:id',
		name: 'edit-members',
		component: EditMembersView,
		meta:{
			title:'The Nagercoil Bar -  Edit Members',
			auth:true
		}
	},
	{
		path: '/privacy-policy',
		name: 'privacy-policy',
		component: PrivacyPolicyView,
		meta:{
			title:'The Nagercoil Bar -  Privacy Policy',
			auth:false
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'not-found/',
		
		component: NotFoundView,
		meta:{
			title:'The Nagercoil Bar -  Not Found',
			auth:false
		}
	},
	{
		path: '/create-gallery/:id',
		name: 'create gallery',
		component:CreateGalleryView,
		meta:{
			title:'The Nagercoil Bar - Create Gallery',
			auth:true
		}
	},
	{
		path: '/add-gallery',
		name: 'add gallery',
		component:AddGalleryView,
		meta:{
			title:'The Nagercoil Bar - Add Gallery',
			auth:true
		}
	},
	{
		path: '/gallery',
		name: 'gallery',
		component:GalleryView,
		meta:{
			title:'The Nagercoil Bar -  Gallery',
			auth:true
		}
	},
	{
		path: '/manage-office-bearers',
		name: 'manage office bearers',
		component:ManageOfficeBearersView,
		meta:{
			title:'The Nagercoil Bar -  Manage Office Bearers',
			auth:true
		}
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to,from,next)=>{
	console.log('to',to);
	console.log('from',from);
	//    console.log('next',next());
   
	if('auth' in to.meta && to.meta.auth && !store.state.authentication.token){
		document.title = `${to.meta.title}`;
		next('/');
	}else if('auth' in to.meta && !to.meta.auth && store.state.authentication.token){
		document.title = `${to.meta.title}`;
		next('/members');
	}else{
		document.title = `${to.meta.title}`;
		next();
	}
	// document.title = `${to.meta.title}`;
	// next();
})

export default router
