<template>
  <CreateAlbum />
  
  <div class="tab-heading-and-action">
    <div>
      <h3>{{ capitalizeString(type) }} Album</h3>
    </div> 
    
    <div class="filter">
      <div class="input-group">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
      <div
        class="add-speakers"
        @click="clickAlbumDialog('add',null)"
      >
        <fa icon="plus" /> Create Album
      </div>
    </div>
  </div>
 
  
  <div
    v-if="albumArray.length > 0 "
    class="album-container"
    style="overflow-y: auto;"
    :style="{height:tableHeight+'px'}"
  >
    <AlbumCard
      v-for="item in albumArray"
      :id="item.id"
      :key="item.id"
      :album-name="item.albumName"
      :cover-picture="item.coverPicture"
      @click-album-dialog="clickAlbumDialog"
      @open-delete-dialog="openDeleteDialog"
    />
  </div>

  <div
    v-else
    style="display: flex;align-items: center;justify-content: center;"
    :style="{height:tableHeight+'px'}"
  >
    <h3 class="">
      No Albums Found
    </h3>
  </div>

  <!-- pagination -->
  <div
    class="text-center album-pagination-container"
    style="padding:0px!important;"
  >
    <div
      v-if="albumArray.length > 0 "
      style="padding:0px!important;margin:auto;"
    >
      <v-row>
        <v-col cols="4" />
        <v-col cols="8">
          <div
        
            style="padding:0px!important;"
          >
            <v-pagination
              v-model="page"
              :length="pageLength"
              class="my-4"
              @update:model-value="getPage"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>


  <!-- delete list dialog -->
  <v-dialog 
    v-model="deleteAlbumDialog"
    width="500"
  >
    <v-card class="delete-dialog">
      <div
        class="close-icon"
        @click="deleteAlbumDialog = false"
      >
        <fa icon="close" />
      </div>
          
            
      <v-card-text>
        <h3> <fa icon="trash" /> Are you sure?</h3>
        Are you sure you want to delete this album? This action cannot be undone. 
      </v-card-text>
       
      <v-card-actions class="delete-action ">
        <button @click="deleteAlbumDialog = false">
          Cancel
        </button>
        <button
          :disabled="deleting?true:false"
          @click="deleteAlbum(deleteId)"
        >
          Delete
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- delete list dialog -->
</template>





<script setup>
import CreateAlbum from '@/components/gallery/CreateAlbum.vue';
import AlbumCard from '@/components/gallery/AlbumCard.vue';
import { useStore } from 'vuex';
import { ref, reactive, watch, provide, onBeforeMount, onMounted, computed } from 'vue'
import { capitalizeString } from '@/helper/index.js';
import { getAlbumList, deleteAlbumData, getAlbumCount } from '@/service/helper-service.js';

const store = useStore(); 
const props = defineProps({
	type:{
		type:String,
		default:''
	}
})



const itemsPerPage = ref(0);
let offset = ref(0);
const page = ref(1);
const pageLength = ref(0);
const tableHeight = ref(0);
const totalItems = ref(0);
const albumId =ref('');
const deleteId = ref('');
const deleteAlbumDialog = ref(false);
const deleting = ref(false);

const name= ref('');
const calories= ref('');
const search= ref('');
const timeout= ref(null);
const albums = ref([]);
const albumArray = ref([]);



let openAlbumDialog = reactive({'status':false});
provide('openAlbumDialog',openAlbumDialog)
provide('albumId',albumId)



watch(name, () => {
	search.value = String(Date.now())
})

watch(calories, () => {
	search.value = String(Date.now())
})



watch(name, () => {
	
	clearTimeout(timeout.value);
	
	timeout.value= setTimeout(()=> {
		page.value = 1;
		loadAlbum()
	},1000)
})




const tableRefresh = computed(() => store.state.gallery.tableRefresh);

watch(tableRefresh, (newValue) => {
	if(newValue === true){
		loadAlbum();
	}
})

onMounted(() => {
	console.log("type",props.type)
	loadAlbum()
})






const clickAlbumDialog = (mode,id) =>{
	console.log("mode",mode);
	if(mode=='edit'){
		console.log("ID",id);
		albumId.value = id;
	}
     
	openAlbumDialog.status = true;
	console.log("openAlbumDialog.value",openAlbumDialog.status);

}

const openDeleteDialog = (id) =>{
	console.log("id",id);
	deleteAlbumDialog.value = true;
	deleteId.value = id;

}








const getPage = () =>{
	loadAlbum()
}


onBeforeMount(() => {
	if (window.innerHeight <= 657) {
		tableHeight.value = window.innerHeight - 300;
		itemsPerPage.value = 10;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		tableHeight.value = window.innerHeight - 300;
		itemsPerPage.value = 15;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		tableHeight.value = window.innerHeight - 300;
		itemsPerPage.value = 20;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		tableHeight.value = window.innerHeight - 300;
		itemsPerPage.value = 25;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		tableHeight.value = window.innerHeight - 300;
		itemsPerPage.value = 30;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		tableHeight.value = window.innerHeight - 300;
		itemsPerPage.value = 35;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		tableHeight.value = window.innerHeight - 300;
		itemsPerPage.value = 40;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		tableHeight.value = window.innerHeight - 300;
		itemsPerPage.value = 50;
		return;
	}
 
})





const loadAlbum = async() =>{
	albumArray.value = [];
	store.state.gallery.tableRefresh = false;
	//offset
	offset.value =  (page.value - 1) * itemsPerPage.value;
	//total pages
	
	try {
		const response = await getAlbumList(`/gallery?limit=${itemsPerPage.value}&offset=${offset.value}&type=${props.type!='all'?props.type:''}&value=${name.value}`);
		if(response.status === 200){
			console.log("response",response);
			albums.value = response?.data?.data;
			totalItems.value = response?.data?.totalCount;
			pageLength.value =  Math.ceil(totalItems.value / itemsPerPage.value);
			response?.data?.data.forEach((item)=>{
				albumArray.value.push({'id':item._id,'albumName':item.albumName,'coverPicture':item.coverPicture.split('/profile/')[1]})
			})
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				console.log("error.response.data.message",error.response.data.message);
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
					
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			console.log("error.message",error.message)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
}



const deleteAlbum = async (id)=>{
	deleting.value = true;
	try{
		const response = await deleteAlbumData(`/gallery/${id}`)
		if(response.status === 200){
			console.log("Deleted",response.data.data);
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Deleted Successfully dd',
				color:'success',
				timeout:2000});
			deleteAlbumDialog.value = false;	
			deleting.value = false;
			loadAlbum();
			updateAlbumCount()
		}
	} catch (error) {
		deleting.value = false;
		if (error.response) {
			if(error.response.data.message){
 
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});	
 
			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
 
	}
}


const updateAlbumCount = async() =>{
	try {
		const response = await getAlbumCount(`/count/gallery`);
		if(response.status === 200){
			let galleryCounts = response?.data?.data; 
			console.log("galleryCounts",galleryCounts);
        
			store.state.gallery.allGallery = galleryCounts?.allGallery;
			store.state.gallery.published = galleryCounts?.published;
			store.state.gallery.unPublished = galleryCounts?.unPublished;
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				console.log("error.response.data.message",error.response.data.message);
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
					
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			console.log("error.message",error.message)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
}





</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 22px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.filter{width: max-content;margin-left: auto;display: flex;align-items: center;}
.input-group{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .input-group .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;margin-left: 15px;}  
 .input-group input{outline: unset;height: 32px;}

 .add-speakers{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;
    text-decoration:none;}
.add-speakers .fa-plus{margin-right: 8px;}

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() thead tr > th{width: 120px;}
.v-table :deep() thead tr > th:nth-child(2){width:300px;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-pen-to-square{margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-paper-plane{color: #007CBA;}

.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}
.album-container{display: flex;flex-wrap: wrap;justify-content:center;padding:0px;margin-left: -12px;margin-right: -12px;}
.album-pagination-container{position: absolute;bottom: 0;left: 0;right: 0;padding: 0;}
nav{padding:0px!important;margin:0px!important;}
.album-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.album-container::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}
.album-container::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}

:deep() .v-pagination__list{justify-content: flex-end;margin-right: 42px;}

@media only screen and (max-width: 320px) {
  .album{flex-basis: 100%;}
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .album-container{flex-direction: row;}
  .album{flex-basis: 40%;}
}
@media only screen and (max-width: 600px) {
  .tab-heading-and-action,.filter{flex-direction: column;}
  .filter{margin-left:unset;align-items: unset;}
  .input-group{margin-bottom: 12px;}
  .add-speakers{margin-left:unset;}
  .album-image,.album-image img{height: 169px;}
  .album-name{padding: 13px 0;}
  
}


</style>






