<template>
  <div class="v-container">
    <h3>{{ formHeading }}</h3>
  </div>
  
  <Form
    ref="form"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <div class="form-container">
      <div class="message-section">
        <div class="form-card">
          <h3>Message</h3>
          <div class="form-col-full">
            <label for="title">Title<span>*</span></label>
            <Field
              v-model="title"
              name="title"
              type="text"
              placeholder="Title"
            />
            <ErrorMessage name="title" /> 
          </div>
           
          <div class="form-col-full">
            <label for="message">Message<span>*</span> </label>
            <Editor v-model="message" editorStyle="height: 320px" />
          </div> 
      
          <div class="form-col-full">
            <label for="gallery">Gallery</label>
  
            <Field
              v-model="gallery"
              name="gallery"
              as="select"
            >
            <option
                value=""
              >
               Gallery
              </option>
              <option
                value="gallery 1"
              >
                gallery 1
              </option>
             
            </Field>
        
            <ErrorMessage name="gallery" />
          </div>
        </div>
      </div> 
      
      <div class="attachment-section">
        <div class="form-card">
          <h3>Files Attachments</h3>
        </div>
      </div>  
    </div>

  
    
    <div class="form-row">
      <div class="form-col-full">
        <div class="form-actions">
          <button
            type="button"
            @click="resetForm"
          >
            Reset
          </button>
          <button type="submit">
            Save
          </button>
        </div>
      </div>
    </div>
  </Form>
  
     
  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}
  
    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>
  
  
  
  
  
<script setup>
import { useRoute,useRouter } from 'vue-router';
import { isValidPhoneNumber} from "libphonenumber-js/mobile"; 
import {ref,onMounted} from 'vue'
import Editor from 'primevue/editor';

  
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
  
// const firstName = ref('');
// const middleName = ref('');
// const lastName = ref('');
// const gender = ref('');
// const email = ref('');
const route = useRoute();
const router = useRouter();
  
const form  = ref();
const dialCode = ref('');
  
const imageUrl = ref('');
const title = ref('');
const message = ref('');

  
const addressLineOne = ref('');
const addressLineTwo = ref('');
const district = ref('');
const pincode =ref('');
const enrollmentNo =ref('');
const typeOfAdvocate = ref('');
const fax = ref('');
const certificateNo = ref('');
const vakalat = ref('');
const firmRegistration = ref('');
const doj = ref('');
// const dob =ref('');
// const chapter = ref('');
const	timeout= ref(2000);
const snackbar= ref(false);
const text= ref('');
const authToken = ref('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWFhMDU2MjRlMDQ2ZDAwMTExMzY0OWQiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MDk2MTc2ODZ9.9HnhhNDGCLTapqazQDsSbJARLDvSFCs1MiQmouf4JrM');
const memberId = ref(route.params.id);
const formHeading = ref('');
  

  
const schema = yup.object({
	title: yup.string().optional(),
	firstName: yup.string().required('Please enter firstname'),
	middleName: yup.string().optional(),
	lastName: yup.string().required('Please enter lastname'),
	dob:yup.string().required('Please enter DOB'),
	gender:yup.string().required('Please select'),
	bloodGroup:yup.string().required('Please enter blood group'),
	email:yup.string().required('Please enter email'),
	phoneNumber:yup.string().required('Please enter phone number').test('custom-validation', 'Invalid phone number', function(value) {
		// console.log('value',value,isValidPhoneNumber(value));
		if (!isValidPhoneNumber(value)) {
			return false; // Validation failed
		}
		return true;
	}),
	addressLineOne: yup.string('').required('Please enter address one'),
	addressLineTwo: yup.string('').required('Please enter address two'),
	district: yup.string('').required('Please select'),
	pincode: yup.string('').required('Please enter pincode'),
	enrollmentNo:yup.string('').required('Please enter enrollment no'),
	typeOfAdvocate:yup.string('').required('Please select'),
	fax:yup.number('').required('Please enter fax'),
	certificateNo:yup.string('').required('Please enter certificate no'),
	vakalat:yup.string('').required('Please enter vakalat'),
	firmRegistration:yup.string('').required('Please select'),
	doj:yup.string().required('Please enter DOJ'),
  
  
});
  
const countryChanged = (country) =>{
	console.log(country);
	dialCode.value = country.dialCode;
}
  
const onSubmit = (data) => {
	console.log('data',data);
      
  
	if(memberId.value){
		axios
			.put(`${process.env.VUE_APP_SERVICE_URL}/users/${memberId.value}`,
				{
					"title": data.title,
					"firstName": data.firstName,
					"middleName": data.middleName,
					"lastName": data.lastName,
					"dateOfBirth": data.dob,
					"gender": data.gender,
					"bloodGroup": data.bloodGroup,
					"email": data.email,
					"phone": {
						"countryCode": '+91',
						"number":data.phoneNumber.replace(data.phoneNumber.split(' ')[0],'').replace(/\D/g, '')
					},
					"addressLine1": data.addressLineOne,
					"addressLine2": data.addressLineTwo,
					"district": data.district,
					"postalCode": data.pincode,
					"enrollmentNo": data.enrollmentNo,
					"advocateType": data.typeOfAdvocate,
					"certificateNo": data.certificateNo,
					"vakalat": data.vakalat,
					"fax": data.fax,
					"firmRegistration": data.firmRegistration,
					"dateOfJoining": data.doj
				},
				{headers:{
					'Authorization': `Bearer ${authToken.value}`,
				}})
			.then((response) => {
				if(response.status === 200){
            
					snackbar.value=true;
					text.value = "Updated Successfully"; 
					form.value.resetForm();
					router.push({name:'members'});
				}
			})
	}else{
		axios
			.post(`${process.env.VUE_APP_SERVICE_URL}/saveMember`,
				{
					"title": data.title,
					"firstName": data.firstName,
					"middleName": data.middleName,
					"lastName": data.lastName,
					"dateOfBirth": data.dob,
					"gender": data.gender,
					"bloodGroup": data.bloodGroup,
					"email": data.email,
					"phone": {
						"countryCode": '+91',
						"number":data.phoneNumber.replace(data.phoneNumber.split(' ')[0],'').replace(/\D/g, '')
					},
					"addressLine1": data.addressLineOne,
					"addressLine2": data.addressLineTwo,
					"district": data.district,
					"postalCode": data.pincode,
					"enrollmentNo": data.enrollmentNo,
					"advocateType": data.typeOfAdvocate,
					"certificateNo": data.certificateNo,
					"vakalat": data.vakalat,
					"fax": data.fax,
					"firmRegistration": data.firmRegistration,
					"dateOfJoining": data.doj
				},
				{headers:{
					'Authorization': `Bearer ${authToken.value}`,
				}})
			.then((response) => {
				if(response.status === 200){
            
					snackbar.value=true;
					text.value = "Added Successfully"; 
					form.value.resetForm();
				}
			})
	}
       
  
  
      
};
  
  
  
const resetForm = ()=>{
	form.value.resetForm()  
}
  
  
const editMember = (id)=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/users/${id}`,{
			headers: {
				'Authorization': `Bearer ${authToken.value}`,
			}})
		.then((response) => {
			console.log("edit response",response);
			if(response.status === 200){
				let getMemberDetails = response.data.data;
          
				// // profile image
				// responseImageUrl.value = `${getMemberDetails.image?getMemberDetails.image :''}`;
				// imageName.value = responseImageUrl.value?responseImageUrl.value.split('/profile/')[1]:'';
				// console.log("imageName.value",imageName.value);
				// if(imageName.value!=''){
				// 	loadImage()
				// }else{
				// 	imageUrl.value = require(`@/assets/images/avatar.svg`);
				// }
				// // profile image
  
				title.value = `${getMemberDetails.title?getMemberDetails.title :''}`;
				firstName.value =  `${getMemberDetails.firstName?getMemberDetails.firstName :''}`; 
				middleName.value =  `${getMemberDetails.middleName?getMemberDetails.middleName :''}`; 
				lastName.value =  `${getMemberDetails.lastName?getMemberDetails.lastName :''}`; 
				dob.value =  `${getMemberDetails.dateOfBirth?getMemberDetails.dateOfBirth :''}`;
				gender.value =  `${getMemberDetails.gender?getMemberDetails.gender :''}`; 
				bloodGroup.value = getMemberDetails.bloodGroup?getMemberDetails.bloodGroup :''; 
				email.value = getMemberDetails.email?getMemberDetails.email :''; 
				phoneNumber.value = getMemberDetails.phone?`${getMemberDetails.phone.countryCode} ${getMemberDetails.phone.number}`:''; 
				addressLineOne.value = getMemberDetails.addressLine1?getMemberDetails.addressLine1 :''; 
				addressLineTwo.value = getMemberDetails.addressLine2?getMemberDetails.addressLine2 :''; 
				district.value = getMemberDetails.district?getMemberDetails.district :''; 
				pincode.value = getMemberDetails.postalCode?getMemberDetails.postalCode :''; 
				enrollmentNo.value = getMemberDetails.enrollmentNo?getMemberDetails.enrollmentNo :''; 
				typeOfAdvocate.value = getMemberDetails.advocateType?getMemberDetails.advocateType :''; 
				fax.value = getMemberDetails.fax?getMemberDetails.fax :''; 
				certificateNo.value = getMemberDetails.certificateNo?getMemberDetails.certificateNo :''; 
				vakalat.value = getMemberDetails.vakalat?getMemberDetails.vakalat :''; 
				firmRegistration.value = getMemberDetails.firmRegistration?getMemberDetails.firmRegistration :''; 
				doj.value = getMemberDetails.dateOfJoining?getMemberDetails.dateOfJoining :''; 
          
				// designation.value = getMemberDetails?.designation || '';
				// about.value = getMemberDetails?.about || '';
  
        
			}
		})
}
  
  
  
onMounted(()=>{
    
	if(memberId.value){
		formHeading.value="Edit BroadCast";
		editMember(memberId.value);
	}else{
		formHeading.value="Send BroadCast";
	}
  
      
	imageUrl.value = require(`@/assets/images/avatar.svg`);
})
  
   
  
  
</script>
  
  
  
  
  <style scoped>
  .form-container{display:flex;}
  .message-section{flex-basis:60%;}
  .attachment-section{flex-basis:40%;}
  .form-card{box-shadow: 0px 2px 12px 0px #80808040;
   border-radius: 12px;width: 97%;margin: 25px auto 0;
  background-color:white;padding: 17px;}
  .form-row{display: flex;}
  label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
  label span{color:red;}
  input + span ,select + span ,.input-group + span,.vue-tel-input + span{color:red;text-align: left;font-size: 12px;}
  .form-col-half,.form-col-full{display: flex;flex-direction: column;flex:1;margin-right:30px;margin-bottom: 14px;}
  .form-col-half:last-child{margin-right:0px;}
  .form-profile{flex-basis: 20%;margin-right: 15px;}
  .profile-container{position: relative; width:170px;height: 170px;border-radius: 50%;margin: auto;
      display: block;}
  .profile-image {position: relative; width:170px;height: 170px;border-radius: 50%;margin:auto;overflow: hidden;}
  .profile-image > img{position: absolute;width: 170px;
      height: 170px;}
  .profile-image > label{ position: absolute;
      bottom: -14px;
      background-color: #5a5a5a73;
      width: 170px;
      height: 80px;
      margin-bottom: 0;
      cursor: pointer;
      text-align: center;}
      .profile-image > label .fa-camera{color: white;
      font-size: 29px;
      margin-top: 13px;}
      
       .remove-profile{
       
        top: 0;
      right: 27px;
      position: absolute;
      z-index: 1040;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #FF0000;
      color: white;
      text-align: center;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
       }
  .title{flex-basis: 14%!important;flex:unset;}
  /* .form-col-half{flex-basis: 50%;} */
  /* .form-col-full{flex-basis: 100%;} */
  .form-col-half input,.form-col-full input,.form-col-half select,.form-col-full select{border:1px solid #B2B2B2;border-radius: 5px;
      height: 38px;padding: 4px 13px;width:100%;}
   .form-col-half input:focus,.form-col-full input:focus,.form-col-half select:focus{outline: unset;}   
   select{border: 1px solid #B2B2B2;
      padding: 6px;
      border-radius: 5px;
      cursor: pointer;
      outline: unset;
      color:black;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
      background-position: 100% 60%;
      background-repeat: no-repeat;
      background-size: auto 15%;
  }
  .form-actions{display: flex;justify-content: flex-end;}
  .form-actions button{background-color: #007CBA;
      color: white;
      border-radius: 7px;
      padding: 6px 28px;
      margin-left: 18px;}
  .error-msg{text-align: left;
      color: red;
      font-size: 12px;}
  :deep() .iti__selected-flag{background-color:unset;}
  
  /* date picker */
  .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
  /* .input-group input{border:unset;outline: unset;} */
  /* .flat-pickr{width:100%!important;}  */
  /* .flatpickr-input{width: 30%;background-color: #007CBA;} */
  :deep() .form-col-half  .date-form-control{
    width: 100%;height: 37px;padding: 12px;}
  
   :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
    
    .input-group-append {
    display: flex;
    align-items: center; /* Aligns buttons vertically */
    /* Add additional styles as needed */
  }
  .input-group-append button{margin-right: 7px;}
  .input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
  .radio-group{display: flex;}
  @media only screen and (max-width: 768px) {
    .form-row{flex-direction: column;}
    .form-col-half, .form-col-full{margin-right: unset;}
  }
  </style>
  
  